<template>
  <div v-if="!isEmptyList()">
    <div v-if="isGridView">
      <div class="text-body-2 font-weight-bold mb-1" v-text="name"></div>
      <div class="text-caption mb-2" v-html="description" v-if="description"></div>
      <component :is="'GridView'" :list="list"></component>
    </div>
    <div class="mt-6" v-if="isListView">
      <div class="category-divider" v-if="index > 0"></div>
      <div class="text-h6 font-weight-bold mb-1" v-text="name"></div>
      <div class="text-caption mb-2" v-html="description" v-if="description"></div>
      <component :is="'ListView'" :list="list"></component>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ListView from '@/components/Menu/ProductViews/ListView';
import GridView from '@/components/Menu/ProductViews/GridView';
export default {
  name: 'SingleProductTab',
  props: ['index', 'view', 'list', 'name', 'description'],
  components: {
    ListView,
    GridView,
  },
  data() {
    return {
      defaultView: true,
    };
  },
  computed: {
    ...mapGetters('cart', {
      isGridView: 'isGridView',
      isListView: 'isListView',
    }),
  },
  methods: {
    isEmptyList() {
      return this.list.length == 0;
    }
  }
};
</script>

<style scoped>
.list-bulleticon {
  font-size: 25px !important;
}
.category-divider {
  width: calc(100% + 32px);
  height: 8px;
  margin-left: -16px;
  margin-bottom: 20px;
  background-color: #B0BEC5;
  opacity: 0.2;
}

@media screen and (min-width: 415px) and (max-width: 1023px) {
  .category-divider {
    width: calc(100% + 16px);
    margin-left: -8px;
  }
}
</style>
