<template>
  <v-bottom-sheet v-model="showOffer">
    <v-card min-height="30vh" max-height="60vh" class="overflow-y-auto p-0">
      <v-card-title class="text-button font-weight-6 justify-center">
        <v-icon color="primary" class="mr-2">mdi-sale</v-icon>
        Offers
      </v-card-title>
      <v-card height="8" color="#f3f3f3"></v-card>
      <v-card flat v-if="offers && offers.length == 0">
        <v-list color="#ffffff">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 12px !important"
                class="text-caption font-weight-bold mt-2"
              >
                No offer at the moment
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card
        flat
        v-for="offer in offers"
        :key="offer.id"
        color="#f3f3f3"
        class="pb-2 px-3"
      >
        <v-list color="#ffffff">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 12px !important"
                class="text-caption font-weight-bold mt-2"
              >
                {{ offer.percentage }}% off with
                <a v-if="offer.min">min. purchase {{ $n(offer.min, 'currency', 'en-MY') }}</a>
                <a v-else>no min. purchase</a>
              </v-list-item-title>
              <v-list-item-subtitle class="mt-2">
                <small style="font-size: 10px !important"
                  >Valid from
                  <a class="font-weight-6">{{
                    formatExpiry(offer.valid_from)
                  }}</a>
                  to
                  <a class="font-weight-6">{{
                    formatExpiry(offer.valid_to)
                  }}</a></small
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'OfferBottomSheet',
  props: ['visible'],
  computed: {
    ...mapState('merchant', {
      offers: (state) => state.offers,
    }),
    showOffer: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close-offer', false);
        }
      },
    },
  },
  methods: {
    formatExpiry(expiry) {
      return dayjs(expiry).format('YYYY-MM-DD');
    },
  },
};
</script>
<style scoped>
.limited-offer {
  font-size: 8px !important;
  font-weight: 600;
  height: 18px;
  border-radius: 8px;
  color: #ff0000;
  background-color: rgba(255, 0, 0, 0.1) !important;
}
</style>